package org.ailingo.app.database

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Unit
import org.ailingo.app.database.composeApp.newInstance
import org.ailingo.app.database.composeApp.schema

public interface HistoryDictionaryDatabase : SuspendingTransacter {
  public val historyDictionaryDatabaseQueries: HistoryDictionaryDatabaseQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = HistoryDictionaryDatabase::class.schema

    public operator fun invoke(driver: SqlDriver): HistoryDictionaryDatabase =
        HistoryDictionaryDatabase::class.newInstance(driver)
  }
}

package org.ailingo.app.database.composeApp

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass
import org.ailingo.app.database.HistoryDictionaryDatabase
import org.ailingo.app.database.HistoryDictionaryDatabaseQueries

internal val KClass<HistoryDictionaryDatabase>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = HistoryDictionaryDatabaseImpl.Schema

internal fun KClass<HistoryDictionaryDatabase>.newInstance(driver: SqlDriver):
    HistoryDictionaryDatabase = HistoryDictionaryDatabaseImpl(driver)

private class HistoryDictionaryDatabaseImpl(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver), HistoryDictionaryDatabase {
  override val historyDictionaryDatabaseQueries: HistoryDictionaryDatabaseQueries =
      HistoryDictionaryDatabaseQueries(driver)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS HistoryDictionaryEntity(
          |    id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          |    text TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
